.xrp-logo {
  height: 100px;
  pointer-events: none;
}

.App-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  height: 250px;
  padding-top: 2%;
}

.header-title {
  font-size: x-large;
  font-weight: bolder;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 20px;
}

.header-info {
  margin-top: 2%;
  padding-left: 1%;
  padding-right: 1%;
  font-style: italic;
  font-size: larger;
  font-weight: bold;
  color: white;
  width: 100%;
  background-color: rgb(69, 86, 235);
  padding-top: 20px;
  padding-bottom: 20px;
}

.page-container {
  margin-left: 10%;
  margin-right: 10%;
}

.section-header {
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 50px;
}
.section-break {
  border-bottom: 5px solid rgb(69, 86, 235);
  border-radius: 4px;
  margin-bottom: 20px;
}

.project-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  align-items: center;
}

.project-icon {
  height: 120px;
}

.project-item-icon {
  margin-right: 20px;
  margin-left: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
}

.project-item-header {
  font-weight: bold;
  font-size: large;
}

.project-item-text {
  line-height: 30px;
  font-size: 15px;
  margin-left: 5%;
  width: 700px;
}

.App-link {
  color: rgb(37, 67, 236);
  text-decoration: none;
  font-family: monospace;
}
.App-link:hover {
  color: rgb(134, 168, 243);
  text-decoration: none;
}
.App-link:active {
  color: rgb(134, 168, 243);
  text-decoration: underline;
}

.meet-the-team-box {
  display: flex;
   flex-direction:row;
   flex-wrap: wrap;
   padding: 30px;
   justify-content: space-between;
   opacity: 0.8;
   min-height: 200px;
}
.image-item {
  margin: 5px;
  max-height: 35vh;
  max-width: 45vh;
}
.text-item {
  margin: 20px;
  width: 300px;
  line-height: 25px;
}

.keep-posted-container {
  background-color: #2b3035;
  height: auto;
}

.keep-posted-content {
  text-align: -webkit-left;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 10px 10px 0 10% ;
  color: rgb(255, 255, 255);
}





